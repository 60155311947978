import React from 'react';
import Link from 'next/link';
import styles from '../../../styles/EmailCollector.module.css';
import clsx from 'clsx';

export const CreateOwnGf = () => {
  return (
    <section className="w-full max-w-screen-lg mx-auto">
      <h2 className="mt-0 mb-[24px] text-center text-[36px] text-wtf-white font-bold">
        Create AI Girlfriend
      </h2>
      <section className="w-full max-w-screen-lg mx-auto">
        <div
          className={clsx(
            'flex-[1] basis-1/3',
            'max-w-[1200px]',
            'hidden lg:!flex lg:!flex-row lg:!justify-center lg:!items-center',
          )}
        >
          <img
            className={'rounded-2xl'}
            src="/features/create-gf-section.jpg"
            alt="create your own ai gilfriend banner preview"
          />
        </div>
        <div className="shadow-darkRed w-full max-w-60 md:max-w-80 md:h-100 h-80 relative overflow-hidden rounded-[16px] !my-2 md:!mb-0 lg:hidden mt-[8px]">
          <p className={'center-content'}>
            Create the partner you&apos;ve always dreamed of. Choose everything
            from her appearance to her personality.
          </p>
          <p className={'center-content'}>
            Real, intimate, and exclusively yours.
          </p>
          <img
            width={100}
            height={100}
            src={'/features/mobile-create-gf-section.png'}
            alt="create your own ai gilfriend banner preview"
            className={clsx(
              'lg:hidden',
              'w-full max-w-[376px]',
              'rounded-[12px]',
            )}
          />
        </div>
      </section>
      <br />
      <div className="flex flex-row justify-center">
        <Link
          className={styles.submitButton}
          href={'/create-your-ai-girlfriend'}
        >
          Create
        </Link>
      </div>
    </section>
  );
};
