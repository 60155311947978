import React from 'react';
import { useTranslation } from 'next-i18next';

import CtaButton from '@components/elements/CtaButton';
import { Accordion } from '@components/HomePage/FAQ/Accordion';

function getData(tt) {
  return [
    {
      question: tt('faq.items.item_1.question'),
      answer: tt('faq.items.item_1.answer'),
    },
    {
      question: tt('faq.items.item_2.question'),
      answer: tt('faq.items.item_2.answer'),
    },
    {
      question: tt('faq.items.item_3.question'),
      answer: tt('faq.items.item_3.answer'),
    },
    {
      question: tt('faq.items.item_14.question'),
      answer: tt('faq.items.item_14.answer'),
    },
    {
      question: tt('faq.items.item_4.question'),
      answer: tt('faq.items.item_4.answer'),
    },
    {
      question: tt('faq.items.item_5.question'),
      answer: tt('faq.items.item_5.answer'),
    },
    {
      question: tt('faq.items.item_6.question'),
      answer: tt('faq.items.item_6.answer'),
    },
    {
      question: tt('faq.items.item_7.question'),
      answer: tt('faq.items.item_7.answer'),
    },
    {
      question: tt('faq.items.item_8.question'),
      answer: tt('faq.items.item_8.answer'),
    },
    {
      question: tt('faq.items.item_9.question'),
      answer: tt('faq.items.item_9.answer'),
    },
    {
      question: tt('faq.items.item_10.question'),
      answer: tt('faq.items.item_10.answer'),
    },
    {
      question: tt('faq.items.item_11.question'),
      answer: tt('faq.items.item_11.answer'),
    },
    {
      question: tt('faq.items.item_12.question'),
      answer: tt('faq.items.item_12.answer'),
    },
    {
      question: tt('faq.items.item_13.question'),
      answer: tt('faq.items.item_13.answer'),
    },
  ];
}

export const FAQ = () => {
  const { t: tt } = useTranslation('home');

  return (
    <section className="px-[20px] w-full max-w-screen-md mx-auto">
      <header className="mb-[48px]">
        <h2 className="text-center text-30px leading-[40px] font-bold mt-0 mb-[32px]">
          {tt('faq.title')}
        </h2>

        <p className="text-center text-wtf-silver">{tt('faq.subtitle')}</p>
      </header>

      <Accordion items={getData(tt)} />

      <section className="flex flex-col items-center">
        <h3 className="text-[24px] text-center">
          {tt('faq.grab_your_ai_girl')}👇🏻
        </h3>

        <CtaButton text={tt('faq.chat')} />
      </section>
    </section>
  );
};
