import React from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import { useTranslation, Trans } from 'next-i18next';

import CtaButton from '@components/elements/CtaButton';

import styles from './Details.module.css';

function getContent(tt) {
  return [
    {
      id: 'girlfriend_chat',
      imgSrc: '/characters/ex-gf.jpg',
      title: tt('details.item_1.title'),
      text: (
        <Trans t={tt} i18nKey="details.item_1.text">
          AI Girlfriend WTF stands out as a top-tier{' '}
          <span className="text-highlight font-semibold">romantic chatbot</span>{' '}
          in 2023, inviting you to dive into wild roleplays with a diverse array
          of virtual girlfriends.
        </Trans>
      ),
      link: '/ai-girlfriend-chat',
    },
    {
      id: 'sexting_ai',
      imgSrc: '/characters/rebecca.webp',
      title: tt('details.item_2.title'),
      text: (
        <Trans t={tt} i18nKey="details.item_2.text">
          In search of an{' '}
          <span className="text-highlight font-semibold">
            AI romantic companion
          </span>
          ? Experience our premium AI chatting capabilities, designed to surpass
          your expectations. Still skeptical? Give our bots a try, completely
          free!
        </Trans>
      ),
      link: '/ai-sexting',
    },
    {
      id: 'nsfw-ai-generator',
      imgSrc: '/features/img-gen-new-preview.jpg',
      title: tt('details.item_3.title'),
      text: (
        <Trans t={tt} i18nKey="details.item_3.text">
          Our nsfw ai art generator specializes in personalization. Get spicy{' '}
          <span className="text-highlight font-semibold">nsfw ai images</span>{' '}
          directly in your chat and tailor those hot details to see your sexy ai
          girlfriend exactly as you desire.
        </Trans>
      ),
      link: '/nsfw-ai-generator',
    },
  ];
}

export const Details = () => {
  const { t: tt } = useTranslation('home');

  const router = useRouter();

  return (
    <section className="w-full max-w-screen-lg mx-auto">
      <h2 className="text-center text-[35px] font-bold my-0 mb-[60px]">
        {tt('details.title')}
      </h2>

      <ul className={clsx('list-none p-0 !px-4', 'flex flex-col gap-[48px]')}>
        {getContent(tt).map((content) => (
          <li key={content.id} className="!m-0">
            <article className="flex flex-col md:flex-row md:items-center">
              <div className="w-full md:w-52 md:h-52 !mb-2 md:!mb-0">
                <img
                  src={content.imgSrc}
                  alt={content.title}
                  className="rounded-md"
                />
              </div>

              <div className="w-full md:w-1/2 px-4 md:ml-8">
                <h3 className="text-2xl font-bold mb-[20px]">
                  {content.title}
                </h3>

                <p key={router.locale}>{content.text}</p>

                {/*<button*/}
                {/*  type="button"*/}
                {/*  className={styles.learnMoreButton}*/}
                {/*  onClick={() => router.push(content.link)}*/}
                {/*>*/}
                {/*  {tt('details.learn_more')}*/}
                {/*</button>*/}
              </div>
            </article>
          </li>
        ))}
      </ul>

      <div className="flex flex-row justify-center mt-[96px]">
        <CtaButton text={tt('details.chat_now')} />
      </div>
    </section>
  );
};
