import { Trans, useTranslation } from 'next-i18next';

import { Link2Icon } from '@components/icons/Link2Icon';
import { LockIcon } from '@components/icons/LockIcon';
import { EyeOffIcon } from '@components/icons/EyeOffIcon';
import clsx from 'clsx';

function getItems(tt) {
  return [
    {
      id: 'ssl-certificate',
      text: tt('privacy.items.ssl_certificate'),
      Icon: Link2Icon,
    },
    {
      id: 'privacy',
      text: tt('privacy.items.privacy'),
      Icon: LockIcon,
    },
    {
      id: 'protected-data',
      text: tt('privacy.items.protected_data'),
      Icon: EyeOffIcon,
    },
  ];
}

export function Privacy() {
  const { t: tt } = useTranslation('home');

  return (
    <section className="w-full max-w-screen-md mx-auto">
      <h2 className="mt-0 mb-[24px] text-center text-[36px] text-wtf-white font-bold">
        {tt('privacy.title')}
      </h2>

      <div
        className={clsx('w-full', 'mb-[48px]', 'flex flex-col items-center')}
      >
        <p className="mt-0 mb-[16px] text-center text-[20px] text-wtf-silver">
          <Trans t={tt} i18nKey="privacy.text_1">
            AI Girlfriend WTF prioritizes your{' '}
            <span className="text-wtf-persianRed">safety</span> and{' '}
            <span className="text-wtf-persianRed">privacy</span>. We do not
            track or sell your data, you are completely safe on our website.
          </Trans>
        </p>
        <p className="m-0 text-center text-[20px] text-wtf-silver">
          {tt('privacy.text_2')}
        </p>
      </div>

      <ul
        className={clsx(
          'list-none m-0 p-0',
          'flex flex-row justify-evenly items-center gap-[32px] flex-wrap',
        )}
      >
        {getItems(tt).map(({ id, text, Icon }) => (
          <li
            key={id}
            className={clsx('!m-0', 'flex flex-col items-center gap-[12px]')}
          >
            <div>
              <Icon size={36} color="#fff" />
            </div>

            <p className="m-0 text-center text-[20px] text-wtf-white">{text}</p>
          </li>
        ))}
      </ul>
    </section>
  );
}
