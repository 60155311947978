import React from 'react';
import Link from 'next/link';
import { Container } from '@ui/Container';
import styles from '../../../styles/EmailCollector.module.css';

/**
 * @returns {JSX.Element}
 */
export function PhotoGen() {
  return (
    <section className="w-full max-w-screen-lg mx-auto">
      <h2 className="mt-0 mb-[24px] text-center text-[36px] text-wtf-white font-bold">
        Advanced AI image Generator
      </h2>

      <p className="mt-0 mb-[16px] text-center text-[20px] text-wtf-silver">
        Our AI art generator unlocks a world where you can not only chat but
        visually experience every spicy moment. We support both realistic and{' '}
        anime AI characters
        <br />
      </p>
      <section className="w-full max-w-screen-md mx-auto">
        <img
          src="/features/img-gen-2.webp"
          alt="ai nsfw image generator preview"
        />
        <Container as="section" className={'mt-24'}>
          <h3
            className={
              'mt-0 mb-[24px] text-center text-[24px] text-wtf-white font-bold'
            }
          >
            AI Magic: Bring Your Ideal Girl to Life
          </h3>
          {/*<video*/}
          {/*  autoPlay*/}
          {/*  loop*/}
          {/*  playsInline*/}
          {/*  muted*/}
          {/*  src="/video/img-gen-vid.mp4"*/}
          {/*  className={*/}
          {/*    'h-full object-cover object-center w-full overflow-hidden grow max-md:max-w-full'*/}
          {/*  }*/}
          {/*>*/}
          {/*  Your browser does not support this video content.*/}
          {/*</video>*/}
          <div className="flex flex-row justify-center mt-24">
            <Link
              className={styles.submitButton}
              href={'/ai-girls?tag=IMAGE_GENERATION&page=1'}
            >
              Chat Now
            </Link>
          </div>
        </Container>
      </section>
    </section>
  );
}
