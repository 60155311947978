import clsx from 'clsx';
import Link from 'next/link';
import styles from '../../styles/EmailCollector.module.css';

const CtaButton = ({ text }) => {
  return (
    <>
      <Link href="/ai-girls" className={styles.submitButton}>
        {text}
      </Link>
    </>
  );
};
export default CtaButton;
