import Link from 'next/link';
import Image from 'next/image';
import { useTranslation, Trans } from 'next-i18next';
import clsx from 'clsx';

import styles from './Hero.module.css';

export function Hero() {
  const { t: tt } = useTranslation('home');

  return (
    <div
      className={clsx(
        'flex flex-col items-center',
        'lg:flex lg:flex-row lg:justify-between lg:items-center lg:gap-[18px]',
      )}
    >
      <div
        className={clsx(
          'flex-[2] basis-2/3',
          'max-w-screen-md',
          'relative',
          'flex flex-col items-center lg:items-start gap-[32px]',
        )}
      >
        {/* The radiance effect */}
        <div className={styles.radiance} />

        <h1 className="m-0 text-center lg:text-start text-[32px] md:text-[64px] !leading-tight">
          <Trans t={tt} i18nKey="hero.title">
            Fun adventures with{' '}
            <span className="text-wtf-persianRed !leading-tight">
              AI Companions
            </span>
          </Trans>
        </h1>

        <img
          src="/main2.png"
          width={660}
          height={560}
          alt="Beautiful AI girl"
          className={clsx(
            'lg:hidden',
            'w-full max-w-[650px]',
            'rounded-[12px]',
          )}
          style={{
            objectFit: 'cover',
          }}
        />

        <div className="my-[12px] lg:my-0">
          <p className="mt-0 mb-[16px] text-center lg:text-start text-[22px] text-wtf-silver font-bold">
            {tt('hero.subtitle')}
          </p>

          <p className="m-0 text-center lg:text-start text-[20px] text-wtf-silver">
            <Trans t={tt} i18nKey="hero.text">
              AI Girlfriends are ready to fulfill{' '}
              <span className="text-wtf-persianRed tracking-wide font-semibold">
                all your desires
              </span>{' '}
              with our chat!
              <br />
              with pleasure. Choose from over 100 fantasy scenarios ranging from
              the casual to the extreme. Enjoy spicy images and create your
              ideal girl — exactly your type. You’ve earned a break!
            </Trans>
          </p>
        </div>

        <div
          className={clsx(
            'w-full lg:w-fit',
            'lg:mt-[12px]',
            'flex flex-col items-center lg:items-start gap-[12px]',
          )}
        >
          <Link
            href="/ai-girls"
            className={clsx(
              'w-full md:max-w-[240px]',
              'p-[16px]',
              'bg-wtf-persianRed',
              'text-center text-[20px] !text-wtf-white font-bold',
              'rounded-[12px]',
              'hover:brightness-110 hover:scale-105',
              'focus:brightness-110 focus:scale-105',
              'transition duration-300',
            )}
          >
            {tt('hero.chat')}
          </Link>

          <p className="m-0 text-[16px] text-wtf-silver">{tt('hero.trial')}</p>
        </div>
      </div>

      <div
        className={clsx(
          'flex-[1] basis-1/3',
          'max-w-[650px]',
          'hidden lg:!flex lg:!flex-row lg:!justify-center lg:!items-center',
        )}
      >
        <img
          src="/main2.png"
          width={650}
          height={550}
          alt="Beautiful AI girl"
          className="rounded-[12px]"
          style={{
            objectFit: 'cover',
          }}
        />
      </div>
    </div>
  );
}
