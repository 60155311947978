import React, { useRef } from 'react';
import { useTranslation } from 'next-i18next';

function getPublications(tt) {
  return [
    {
      url: 'https://topai.tools/t/ai-girlfriend-wtf?ref=embed',
      imageSrc: 'https://topai.tools/assets/img/topai.tools.gif',
      altText: tt('as_seen_in.topaitools'),
      maxWidth: '224px',
      maxHeight: '80px',
    },
    {
      url: 'https://www.aitoolhunt.com/tool/aigirlfriend.wtf?utm_medium=featured&utm_source=aigirlfriend.wtf',
      imageSrc: 'https://www.aitoolhunt.com/images/featured-dark.png?a=1',
      altText: tt('as_seen_in.aitoolhunt'),
      maxWidth: '224px',
      maxHeight: '80px',
    },
    {
      url: 'https://theresanaiforthat.com/ai/ai-girlfriend-wtf/?ref=featured&v=549710',
      imageSrc: 'https://media.theresanaiforthat.com/featured3.png',
      altText: 'AI Girlfriend WTF featured on theresanaiforthat website',
      maxWidth: '250px',
      maxHeight: '80px',
    },
    {
      url: 'https://www.reddit.com/',
      imageSrc: '/seen/red2.png',
      altText: tt('as_seen_in.reddit'),
      maxWidth: '154px',
      maxHeight: '80px',
    },
    // {
    //   url: 'https://nsfwph.com/',
    //   imageSrc: '/seen/nsfwcom.png',
    //   altText: tt('as_seen_in.nsfwph'),
    //   maxWidth: '154px',
    //   maxHeight: '80px',
    // },
    // {
    //   url: 'https://subscribestar.adult/ai-girlfriend-wtf',
    //   imageSrc: '/seen/subscribestar-logo.png',
    //   altText: 'AI Girlfriend WTF on Subscribestar Adult',
    //   maxWidth: '200px',
    //   maxHeight: '80px',
    // },
    {
      url: 'https://easywithai.com/',
      imageSrc: '/seen/easywithai.webp',
      altText: tt('as_seen_in.easywithai'),
      maxWidth: '154px',
      maxHeight: '80px',
    },
    {
      url: 'https://youtu.be/c55_zqpJNRs?si=M6t_kQf3AKlDQtjK/',
      imageSrc: '/seen/yt2.png',
      altText: tt('as_seen_in.youtube'),
      maxWidth: '200px',
      maxHeight: '80px',
    },
    {
      url: 'https://www.thataicollection.com/application/ai-girlfriend-wtf?utm_source=ai-girlfriend-wtf_embed',
      imageSrc: 'https://cdn.ai-collection.org/aicollection_featured.svg',
      altText: 'AI Girlfriend WTF featured on ai collection website badge',
      maxWidth: '200px',
      maxHeight: '80px',
    },
    {
      url: 'https://nsfw.tools/products/ai-girlfriend-wtf',
      imageSrc: '/seen/nsfwtools-badge.png',
      altText: 'AI Girlfriend WTF featured on nsfw tools',
      maxWidth: '200px',
      maxHeight: '80px',
    },
    {
      url: 'https://futuretoolsweekly.io/aigirlfriend-wtf',
      imageSrc: '/seen/futuretoolsweeklyio.png',
      altText: 'AI Girlfriend WTF featured on future tools weekly io',
      maxWidth: '200px',
      maxHeight: '80px',
    },
    {
      url: 'https://aimojo.pro/tools/aigirlfriend-wtf',
      imageSrc: '/seen/ai-mojo.png',
      altText: 'AI Girlfriend WTF featured on AI Mojo',
      maxWidth: '200px',
      maxHeight: '80px',
    },
    // ... more publications
  ];
}

export const AsSeenIn = () => {
  const { t: tt } = useTranslation('home');

  /**
   * @type {React.MutableRefObject<HTMLDivElement | null>}
   */
  const marqueeRef = useRef(null);

  const renderItems = () => {
    return getPublications(tt).map((pub, index) => (
      <a
        key={index}
        href={pub.url}
        target="_blank"
        rel="noopener noreferrer"
        className="shrink-0 flex flex-row justify-center items-center"
        style={{ maxWidth: pub.maxWidth, maxHeight: pub.maxHeight }}
      >
        <img
          src={pub.imageSrc}
          alt={pub.altText}
          style={{ width: '100%', height: '100%' }}
        />
      </a>
    ));
  };

  return (
    <section>
      <h2 className="text-center text-[24px] lg:text-[32px] font-semibold tracking-widest mt-0 mb-[24px]">
        {tt('as_seen_in.title')}
      </h2>

      <div className="overflow-hidden">
        <div
          className="flex gap-[32px] select-none animate-marquee"
          style={{
            width: marqueeRef.current?.clientWidth || '100%',
          }}
        >
          <div
            ref={marqueeRef}
            className="shrink-0 flex justify-around items-center gap-[32px]"
          >
            {renderItems()}
          </div>
          <div
            aria-hidden
            className="shrink-0 flex justify-around items-center gap-[32px]"
          >
            {renderItems()}
          </div>
        </div>
      </div>
    </section>
  );
};
